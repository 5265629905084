/**
 * Created by gantushig on 5/10/20.
 */
import React from "react";
import "./Marker.scss";

const Marker = ({color, name, id}) => {
    return (
        <div>
            <div
                className="pin bounce"
                style={{backgroundColor: color, cursor: 'pointer'}}
                title={name}
            />
            <div className="pulse"/>
        </div>
    );
};

export default Marker;