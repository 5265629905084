import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
//import {Button} from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import Marker from "../components/Marker";

//style={{position:"relative", left:"80px", top:"150px", backgroundColor:"white", color:"#E62C58", padding:"20px", borderStyle:"outset"}}

// {/*
//  <h5>Та мобайл аппликейшн хийлгэх бодолтой байна уу. Эсвэл маш хурдтай мобайл веб сайт ?</h5>
//  <h3>Бид хамгийн сайныг нь хийдэг!</h3>
//  */}

const IndexPage = () => {
    const siteTitle = "iNext"

    function createMapOptions(maps) {
        return {
            disableDefaultUI: false,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            styles: [{featureType: 'poi', elementType: 'labels', stylers: [{visibility: 'on'}]}],
        };
    }

    const center = {
        lat: 47.916041,
        lng: 106.919922
    };
    const zoom = 16;


    return (
        <>
        <Header siteTitle={siteTitle}/>
        <SEO
            title="Contact"
            keywords={[`mongolia`, `mobile`, `javascript`, `web`, `ios`, `android`, `inext`]}
        />
        <div class="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-12">
                    <div style={{backgroundColor: "white", padding: "20px", marginTop: "80px"}}>

                        <h1>Contact Us</h1>
                        <form name="contact" action="https://formspree.io/xdowawjq" method="POST">
                            <div class="form-group row">
                                <label className="col-sm-2 col-form-label" for="contact_name">Your Name: </label>
                                <div className="col-sm-10">
                                    <input className="form-control" type="text" name="name" id="contact_name"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label className="col-sm-2 col-form-label" for="contact_email">Your Email: </label>
                                <div className="col-sm-10">
                                    <input className="form-control" type="email" name="email" id="contact_email"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label className="col-sm-2 col-form-label" for="contact_message">Message: </label>
                                <div className="col-sm-10">
                                <textarea className="form-control" id="contact_message" name="message"
                                          rows="3"></textarea>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">Send</button>
                        </form>
                        <div style={{marginLeft:"10px", marginBottom: "100px"}}>

                                <h4>INEXT Co.Ltd</h4>

                                <p>International Commercial Center</p>
                                <p>Jamiyan Gun Street -9</p>
                                <p>Khoroo #1, Sukhbaatar District</p>

                                <p>Ulaanbaatar</p>
                                <p>Mongolia</p>

                                <br/>
                                <p><span>Утас:</span> +(976) 85 15 06 90</p>
                                <p><span></span> +(976) 80 80 46 90</p>
                                <p><span>Email:</span> <a href="contact@inextmon.com">contact@inextmon.com</a></p>

                                <div style={{height: '400px', width: '100%'}}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{key: "AIzaSyB-d1Q5VMYzzLLmyBUyUvYhiT5IjJWgDl4"}}
                                        options={createMapOptions}
                                        defaultCenter={center}
                                        defaultZoom={zoom}
                                    >
                                        <Marker
                                            lat={47.916041}
                                            lng={106.919922}
                                            name="INEXT"
                                            color="#E62C58"
                                        />
                                    </GoogleMapReact>
                                </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>

    )
}

export default IndexPage
